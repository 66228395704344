import {
  computed,
} from "@vue/composition-api"
import readXlsxFile from "read-excel-file"
import state from "../state"
import {
  useInputOption,
} from "../viewModel"
import useToast from "@/utils/toast"
import useErrorReasonModal from "./useErrorReasonModal"
import {
  any,
} from "@/utils/value"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    updateOptionsMeta,
  } = useInputOption()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErrorReasonModal()

  const turnOnSidebar = () => {
    state.uploadOptionExcelSidebar.excelFile = null
    state.uploadOptionExcelSidebar.jsonFormatData = null
    state.uploadOptionExcelSidebar.isVisible = true
  }

  const turnOffSidebar = () => {
    state.uploadOptionExcelSidebar.isVisible = false
  }

  const parseExcelData = items => {
    if (!items || items.length === 0) {
      throw Error("빈 파일입니다.")
    }
    const fieldNameMapping = {
      추가가격: "add_price",
      추가공급가: "add_supply_price",
      추가태그바이수수료: "add_tagby_fee",
      재고수량: "stock",
    }

    const fieldsRow = items[0].filter(e => e != null)

    const categoryList = fieldsRow.reduce((data, fieldName) => {
      const field = fieldNameMapping[fieldName]
      if (field == null) {
        return [...data, fieldName]
      }
      return data
    }, [])

    const fieldIndexMapping = fieldsRow.reduce((data, fieldName, i) => {
      const field = fieldNameMapping[fieldName]
      if (data[field] === -1) {
        return {
          ...data,
          [field]: i,
        }
      }
      return data
    }, {
      add_price: -1,
      add_supply_price: -1,
      add_tagby_fee: -1,
      stock: -1,
    })

    if (categoryList.length === 0) {
      throw Error("옵션 카테고리가 없습니다.")
    }

    Object.entries(fieldNameMapping).forEach(([fieldName, field]) => {
      const i = fieldIndexMapping[field]
      if (i === -1) {
        throw Error(`${fieldName} 열이 없습니다.`)
      }
    })

    const categoryByIndex = categoryList.reduce((acc, category, i) => ({
      ...acc,
      [i]: category,
    }), {})

    const fieldByIndex = Object.entries(fieldIndexMapping).reduce((acc, [field, i]) => ({
      ...acc,
      [i]: field,
    }), categoryByIndex)

    const itemRows = items
      // 컬럼명을 제외
      .slice(1)
      // 빈 로우를 제외
      .filter(a => any(a))
      // 빈 컬럼을 제외
      .map(a => a.filter(e => e != null))

    itemRows.forEach((row, i) => {
      if (row.length !== fieldsRow.length) {
        throw Error(`${i + 1}번 행에 문제가 있습니다.`)
      }
    })

    const optionItemList = itemRows.map(row => row.reduce((acc, value, i) => {
      const field = fieldByIndex[i]
      if (categoryList.includes(field)) {
        return {
          ...acc,
          options: [
            ...acc.options,
            { category: field, name: value },
          ],
        }
      }
      return {
        ...acc,
        [field]: value,
      }
    }, { options: [] }))

    const initialCategoryNames = categoryList.reduce((acc, category) => ({
      ...acc,
      [category]: [],
    }), {})

    const categoryNameMapping = itemRows.reduce((data, row) => row.reduce((acc, value, i) => {
      const field = fieldByIndex[i]
      if (categoryList.includes(field) && !acc[field].includes(value)) {
        return {
          ...acc,
          [field]: [...acc[field], value],
        }
      }
      return acc
    }, data), initialCategoryNames)

    const categoryNames = categoryList.map(category => categoryNameMapping[category])

    return {
      optionMeta: {
        categories: categoryList,
        names: categoryNames,
      },
      options: optionItemList,
    }
  }

  const getCopiedOptions = (validOptions, allOptions) => {
    const validOptionByIdx = validOptions.reduce((acc, e) => {
      const optionId = e.options.map(o => o.name).join(",")
      return {
        ...acc,
        [optionId]: e,
      }
    }, {})

    return allOptions.map(e => {
      const optionId = e.options.map(o => o.name).join(",")
      const validOption = validOptionByIdx[optionId]
      if (validOption == null) {
        return {
          ...e,
          state: "INACTIVE",
        }
      }
      return {
        ...e,
        ...validOption,
      }
    })
  }

  const uploadOptionExcel = () => {
    const value = state.uploadOptionExcelSidebar.excelFile
    if (value == null) return
    state.uploadOptionExcelSidebar.isUploading = true
    readXlsxFile(value).then(items => {
      const {
        optionMeta,
        options,
      } = parseExcelData(items)

      state.optionType = "COMBI"
      state.manageOption.optionCategories = optionMeta.categories
      state.manageOption.optionNames = optionMeta.names.map(e => e.join(","))
      updateOptionsMeta()
      state.optionList = getCopiedOptions(options, state.optionList)
      turnOffSidebar()
    }).catch(err => {
      makeToast("danger", "엑셀 내용을 해석하는데 실패했습니다")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.uploadOptionExcelSidebar.isUploading = false
    })
  }

  const isValid = computed(() => Boolean(state.uploadOptionExcelSidebar.excelFile))

  return {
    turnOnSidebar,
    turnOffSidebar,
    uploadOptionExcel,
    isValid,
  }
}
